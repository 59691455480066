
.footer a:link    {
    text-decoration: none !important;
    font-size: 0.75rem;
    font-weight: 700;
    color:var(--clr_pri_2);
}
.footer a:visited {
    color:var(--clr_pri_2);
}
.footer a:hover   {
    color:var(--clr_1_inv);
}
.footer a:active  {
    color:var(--clr_pri_1);
}