:root{
    --clr_1:rgba(237,29,36);
    --clr_1_inv:rgba(18,226,219);
    --clr_1_gry:rgba(122,122,122);
    --clr_1_blk:rgba(0,0,0);

    --clr_pri_0:rgba(255,255,255);
    --clr_pri_1:rgba(240,240,240);
    --clr_pri_2:rgba(60,60,60);


}
.zindex-100{
    z-index: 100;
}
.zindex-200{
    z-index: 200;
}
.zindex-300{
    z-index: 300;
}
.zindex-400{
    z-index: 400;
}
.zindex-500{
    z-index: 500;
}
.zindex-600{
    z-index: 600;
}
.zindex-700{
    z-index: 700;
}
.zindex-800{
    z-index: 800;
}
.zindex-900{
    z-index: 900;
}
/* BASE COLORS*/
.clr-1{color:var(--clr_1);}
.clr-1-inv{color:var(--clr_1_inv);}
.clr-1-gry{color:var(--clr_1_gry);}

.clr-primary-0{color:var(--clr_pri_0);}
.clr-primary-1{color:var(--clr_pri_1);}
.clr-primary-2{color:var(--clr_pri_2);}

.bck-clr-primary-0{background-color:var(--clr_pri_0);}
.bck-clr-primary-1{background-color:var(--clr_pri_1);}
.bck-clr-primary-2{background-color:var(--clr_pri_2);}


/* CONTAINERS */
.logo-container{
    width: 5%;
    height: auto;
    /* Magic! */
    max-width: 5vw;
    padding-top: 10px;

}
.container-1-25{
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_pri_1);
    color: var(--clr_pri_2);
    margin: 5px;
    width: 25px;
    height: 25px;
    border-radius: 5px;
}
   
.container-1{
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_pri_1);
    color: var(--clr_pri_2);
    margin: 8px;
    padding:8px;
    border-radius: 6px;
    box-shadow: 
    10px 10px 10px -1px rgba(60,60,60, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
}

.container-2{
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_pri_0);
    color: var(--clr_pri_2);

    padding:8px;
    border-radius: 6px;
    box-shadow: 
    10px 10px 10px -1px rgba(60,60,60, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
}

.img-container-1{
     width: 100%;
    height: auto;
    /* Magic! */
    max-width: 150vw;
      
}
.img-container-2{
    width: 100px;
    height: 100px;
    object-fit: cover;
     
}
.img-cotainer-3{
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.img-container-5{
    max-width: 50px;
    height: auto;
    width: auto\9; /* ie8 */
}

.img-container-circle-5{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

/**ICON BUTTON CONTAINER **/

.container-h-150{
    height: 150px;
}
.container-h-100{
    height: 100px;
}
.icon-container-1{
    display: inline;
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 0.545454rem;
    padding:1%;
    margin:1%;
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_pri_0);
    color: var(--clr-primary-2);
    box-shadow: 
    10px 10px 10px -1px rgba(60,60,60, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
    transition: 0.33s;
}

@keyframes slideup {
    from{bottom:-65px}
    to {bottom: -75px;}

    
}
@keyframes slidedown {
    from {bottom: -75px;}
    to{bottom:-65px} 
}

.icon-container-1-title{
    height:52px;
    border-radius: 6px;
    border-color: transparent;
    position: absolute;
    background-color: var(--clr_pri_2);
    color:var(--clr_pri_1);
    bottom: -63px;

    text-align: center;
    left:-10%;
    font-size: 0.75rem;
    font-weight: 800;
    opacity: 0;
    padding: 20%;
    
    animation-name: slideup;
    animation-duration: 350ms;
    transition: all 350ms ease-in-out;
}

.icon-container-1-title-pointer{
    
}

.icon-container-1:hover{
    z-index:555555 !important;
    color: var(--clr_1_inv);

    box-shadow: 
     1px 1px 1px -1px rgba(60,60,60, 0.16),
     -1px -1px 1px -1px rgba(255, 255, 255, 0.70);
    transition: 350ms;
}

.icon-container-1:hover .icon-container-1-title{
    background-color: var(--clr_pri_2);
    color:var(--clr_pri_1);
    animation-name: slidedown;
    animation-duration: 350ms;
    opacity: 1;
    transition: all 350ms ease-in-out;
}



.icon-text-container-0{
    
    max-width:150px;
    height: auto;
    width: auto\9; /* ie8 */
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_1);
    color: var(--clr_pri_1);
    padding:8px;
    border-radius: 6px;
    box-shadow: 
    10px 10px 10px -1px rgba(60,60,60, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
}

.icon-text-container-0:hover{
    background-color: var(--clr_pri_1);
    color: var(--clr_pri_2);
    box-shadow: 
    1px 1px 1px -1px rgba(60,60,60, 0.16),
    -1px -1px 1px -1px rgba(255, 255, 255, 0.70);
    transition: 0.5s;
}

.icon-text-container-1{
    
    max-width:150px;
    height: auto;
    width: auto\9; /* ie8 */
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_pri_0);
    color: var(--clr-primary-2);
    padding:8px;
    border-radius: 6px;
    box-shadow: 
    10px 10px 10px -1px rgba(60,60,60, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
}

.icon-text-container-1:hover{
    box-shadow: 
    1px 1px 1px -1px rgba(60,60,60, 0.16),
    -1px -1px 1px -1px rgba(255, 255, 255, 0.70);
    transition: 0.5s;
}

.icon-text-container-1-selected{
    
    max-width:150px;
    height: auto;
    width: auto\9; /* ie8 */
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_1_inv);
    color: var(--clr-1-inv);
    padding:8px;
    border-radius: 6px;
    box-shadow: 
    1px 1px 1px -1px rgba(60,60,60, 0.16),
    -1px -1px 1px -1px rgba(255, 255, 255, 0.70);
}

.icon-text-container-2{

    height: auto;
    width: 100%;
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_1_blk);
    color: var(--clr_pri_0);
    padding:8px;
    border-radius: 6px;
    box-shadow: 
    10px 10px 10px -1px rgba(60,60,60, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
}

.icon-text-container-2:hover{
    background-color: var(--clr_1_gry);
    box-shadow: 
    1px 1px 1px -1px rgba(60,60,60, 0.16),
    -1px -1px 1px -1px rgba(255, 255, 255, 0.70);
    transition: 0.5s;
}


.icon-text-container-3{
    
    max-width:150px;
    height: auto;
    width: auto\9; /* ie8 */
    border: 1px solid var(--clr_pri_1);
    background-color: var(--clr_pri_1);
    color: var(--clr_pri_2);
    padding:5px;
    border-radius: 6px;
    box-shadow: 
    10px 10px 10px -1px rgba(60,60,60, 0.16),
    -10px -10px 10px -1px rgba(255, 255, 255, 0.70);
}

.icon-text-container-3:hover{
    background-color: var( --clr_1);
    color: var(--clr_pri_1);
    box-shadow: 
    1px 1px 1px -1px rgba(60,60,60, 0.16),
    -1px -1px 1px -1px rgba(255, 255, 255, 0.70);
    transition: 0.5s;
}

.icon-body-container-1{
    font-size: 1rem;
    font-weight: 900;
    width: 45px;
    height: 45px;
    border-radius: 0.545454rem;
    padding:10px;
    margin: 5px;
    border: 0px solid var(--clr_pri_1);
    background-color: var(--clr_1);
    color: var(--clr_pri_1);
    box-shadow: 
    5px 5px 10px 0 rgba(60, 60, 60, 0.25),
    8px 8px 1px 0 rgba(255, 255, 255, 0.3);
}

.icon-body-container-1:hover{
    border: 0px;
    background-color:var(--clr_pri_1);
    color: var(--clr_pri_2);
    box-shadow: 
    12px 12px 16px 0 rgba(60, 60, 60, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    transition: 0.5s;
}

.icon-card-container-1{
    font-size: 1rem;
    font-weight: 900;
    width: 45px;
    height: 45px;
    border-radius: 0.545454rem;
    padding:10px;
    margin: 5px;
    border: 0px solid var(--clr_pri_1);
    background-color: var(--clr_1_inv);
    color: var(--clr_pri_1);
}

.icon-card-container-1:hover{
    color: var(--clr_pri_2);
    background-color: var(--clr_pri_1);
    box-shadow: 
    12px 12px 16px 0 rgba(60, 60, 60, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    transition: 0.5s;
}

.glass-container-1{
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.glass-container-2{
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
position: relative;
border-radius: 10px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
z-index: inherit;

}

/*Padding */
.p-100{padding: 100px;}
.p-75{padding: 75px;}
.p-50{padding: 50px;}
.p-25{padding: 25px;}
.p-15{padding: 15px;}
.p-10{padding: 10px;}
.p-0{padding:0;}


/*Padding Top */
.p-t-100{padding-top: 100px;}
.p-t-75{padding-top: 75px;}
.p-t-50{padding-top: 50px;}
.p-t-40{padding-top: 40px;}
.p-t-30{padding-top: 30px;}
.p-t-25{padding-top: 25px;}
.p-t-20{padding-top: 20px;}
.p-t-10{padding-top: 10px;}

/*Padding Bottom */
.p-b-500{padding-bottom: 500px;}
.p-b-400{padding-bottom: 400px;}
.p-b-300{padding-bottom: 300px;}
.p-b-200{padding-bottom: 200px;}
.p-b-175{padding-bottom: 175px;}
.p-b-150{padding-bottom: 150px;}
.p-b-125{padding-bottom: 125px;}
.p-b-100{padding-bottom: 100px;}
.p-b-75{padding-bottom: 75px;}
.p-b-50{padding-bottom: 50px;}
.p-b-40{padding-bottom: 40px;}
.p-b-25{padding-bottom: 25px;}
.p-b-20{padding-bottom: 20px;}
.p-b-10{padding-bottom: 10px;}
.p-b-5{padding-bottom: 5px;}

/*Padding Left */
.p-l-100{padding-left: 100px;}
.p-l-75{padding-left: 75px;}
.p-l-50{padding-left: 60px;}
.p-l-50{padding-left: 50px;}
.p-l-40{padding-left: 40px;}
.p-l-30{padding-left: 30px;}
.p-l-25{padding-left: 25px;}
.p-l-20{padding-left: 20px;}
.p-l-10{padding-left: 10px;}
.p-l-0{padding-left: 0;}
.pp-l-10{padding-left: 10%;}
.pp-l-33{padding-left: 33.3333333%;}

/*Padding Right */
.p-r-500{padding-right: 500px;}
.p-r-400{padding-right: 400px;}
.p-r-300{padding-right: 300px;}
.p-r-200{padding-right: 200px;}
.p-r-175{padding-right: 175px;}
.p-r-150{padding-right: 150px;}
.p-r-125{padding-right: 125px;}
.p-r-110{padding-right: 110px;}
.p-r-100{padding-right: 100px;}
.pp-r-33{padding-right: 1.33%;}
.p-r-75{padding-right: 75px;}
.p-r-50{padding-right: 50px;}
.p-r-40{padding-right: 40px;}
.p-r-25{padding-right: 25px;}
.p-r-20{padding-right: 20px;}
.p-r-10{padding-right: 10px;}


/*Margins*/
.m-100{margin: 100px;}
.m-75{margin: 75px;}
.m-50{margin: 50px;}
.m-25{margin: 25px;}
.m-10{margin: 10px;}

/*Margin Top */
.m-t-100{margin-top: 100px;}
.m-t-75{margin-top: 75px;}
.m-t-50{margin-top: 50px;}
.m-t-25{margin-top: 25px;}
.m-t-10{margin-top: 10px;}

/*Margin Bottom */
.m-b-100{margin-bottom: 100px;}
.m-b-75{margin-bottom: 75px;}
.m-b-50{margin-bottom: 50px;}
.m-b-25{margin-bottom: 25px;}
.m-b-10{margin-bottom: 10px;}

/*Margin Left */
.m-l-100{margin-left: 100px;}
.m-l-75{margin-left: 75px;}
.m-l-50{margin-left: 50px;}
.m-l-25{margin-left: 25px;}
.m-l-10{margin-left: 10px;}

/*Margin Right */
.m-r-200{margin-right: 200px;}
.m-r-175{margin-right: 175px;}
.m-r-150{margin-right: 150px;}
.m-r-125{margin-right: 125px;}
.m-r-100{margin-right: 100px;}
.m-r-75{margin-right: 75px;}
.m-r-50{margin-right: 50px;}
.m-r-25{margin-right: 25px;}
.m-r-10{margin-right: 10px;}

/*RADIUS*/
.rad-1{border-radius: 1px;}
.rad-2{border-radius: 2px;}
.rad-3{border-radius: 3px;}
.rad-4{border-radius: 4px;}
.rad-5{border-radius: 5px;}
.rad-5{border-radius: 5px;}
.rad-10{border-radius: 10px;}
.rad-15{border-radius: 15px;}
.rad-20{border-radius: 20px;}
.rad-25{border-radius: 25px;}

.rad-tl-5{border-top-left-radius: 5px;}
.rad-tr-5{border-top-right-radius: 5px;}

.rad-tl-16{border-top-left-radius: 16px;}
.rad-tr-16{border-top-right-radius: 16px;}

/*LINES */
.line-clr-1-gry-bottom{border-bottom: 1px solid var(--clr_1_gry);}/* Orginal Color : 0f1922ff*/
.line-clr-1-gry-top{border-top: 1px solid var(--clr_1_gry);}/* Orginal Color : 0f1922ff*/
.line-clr-1-gry-left{border-left: 1px solid var(--clr_1_gry);}/* Orginal Color : 0f1922ff*/
.line-clr-1-gry-right{border-right: 1px solid var(--clr_1_gry);}/* Orginal Color : 0f1922ff*/

/*ALIGNMENT*/
.center{
    display: block;
    align-items: center;
    justify-content: center;
}
.right{
    text-align: right;
}
.image-center{
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; 
}
.bottom{
    position: absolute;
    bottom: 0;

}