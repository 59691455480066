@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;800;900&family=Roboto:wght@300;400&display=swap'); 

html,
body {
   
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', sans-serif; 
  line-height: 1.5;
  color: rgba(60,60,60);
  padding-left: 2.33333%;
  /*background-color: rgba(240,240,240);*/
}

/*Font */
.font-size-300{font-size: 3rem;}
.font-size-275{font-size: 2.75rem;}
.font-size-250{font-size: 2.5rem;}
.font-size-225{font-size: 2.25rem;}
.font-size-200{font-size: 2rem;}
.font-size-175{font-size: 1.75rem;}
.font-size-150{font-size: 1.5rem;}
.font-size-125{font-size: 1.25rem;}
.font-size-100{font-size: 1rem;}
.font-size-75{font-size: 0.75rem;}
.font-size-50{font-size: 0.5rem;}
.font-size-25{font-size: 0.25rem;}

.font-light {font-weight: 300;}
.font-regular {font-weight: 400;}
.font-heavy {font-weight: 700;}
.font-heavy8{font-weight: 800;}
.font-heavy9{font-weight: 900;}

font-bold{font-style: bold;}
